function checkServiceAdmin(user) {
  return user.service_role === 'admin'
}

function checkCompanyAdmin(user) {
  return user.company_role === 'admin'
}

function hasCustomRole(user, role) {
  if (checkCompanyAdmin(user)) return true
  if (user.custom_roles.includes(role)) return true
  return false
}

const acl = {
  user: {
    create: (user) => {
      return hasCustomRole(user, 'manage_user')
    },
    import: (user) => {
      return hasCustomRole(user, 'manage_user')
    },
    updateImage: (user) => {
      return hasCustomRole(user, 'manage_user')
    },
    updatePassword: (user) => {
      return hasCustomRole(user, 'manage_user')
    },
    updateRole: (user) => {
      return hasCustomRole(user, 'manage_user')
    },
    updateStatus: (user) => {
      return hasCustomRole(user, 'manage_user')
    },
    export: (user) => {
      return hasCustomRole(user, 'export_user')
    },
    accessible_retired: (user) => {
      return hasCustomRole(user, 'accessible_retired')
    },
  },
  history: {
    delete: (user) => {
      return hasCustomRole(user, 'manage_user')
    },
  },
  field: {
    create: (user) => {
      return checkCompanyAdmin(user)
    },
  },
  permission_group: {
    create: (user) => {
      return checkCompanyAdmin(user)
    },
  },
  layout: {
    create: (user) => {
      return checkCompanyAdmin(user)
    },
  },
  company: {
    create: (user) => {
      return checkServiceAdmin(user)
    },
  }
}

export const can = (user, verb, subject, ...args) => {
  return acl[subject][verb](user, ...args)
}

import Vue from 'vue/dist/vue.esm'
import App from './app.vue'
import VueRouter from 'vue-router'
import VueCookie from 'vue-cookie'
import Vuex from 'vuex'
import router from './routes'
import store from './store'
import axios from 'axios'
import _pick from 'lodash/pick'


// Fontawsome
import { dom } from '@fortawesome/fontawesome-svg-core'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
dom.watch() // This will kick of the initial replacement of i to svg tags and configure a MutationObserver
library.add(fas, far, fab)
Vue.component('fa-icon', FontAwesomeIcon)


// Buefy
import Buefy from 'buefy'
// import 'buefy/dist/buefy.css' // カスタマイズする場合はコメントアウト
Vue.use(Buefy, {
  defaultIconPack: 'fas',
})


// Tippy
import VueTippy, { TippyComponent } from 'vue-tippy'
// import 'tippy.js/themes/light.css' // application.scssでimport (暫定Heroku対応)
Vue.use(VueTippy, {
  animation: 'scale',
})
Vue.component('tippy', TippyComponent)


// Toasted
import Toasted from 'vue-toasted'


// VueProgressBar
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  color: '#64B5F6',
  failedColor: 'red',
  thickness: '4px',
})


// Dayjs
import dayjs from 'dayjs'
import 'dayjs/locale/ja'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.locale('ja')
dayjs.extend(localizedFormat)
Vue.prototype.$date = dayjs


// axios
axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
}

// レスポンスのtokenを取得
axios.interceptors.response.use((res) => {
  if (res.headers['access-token']) {
    // tokenをstoreにcommit
    const authHeaders = _pick(res.headers, ['access-token', 'client', 'expiry', 'uid', 'token-type'])
    store.commit('auth', authHeaders)

    // Cookieにも保存する
    let session = VueCookie.get('session')
    if (session) {
      session = JSON.parse(session)
      session['tokens'] = authHeaders
      VueCookie.set('session', JSON.stringify(session), { expires: '14D' })
    }
  }
  return res
}, (err) => {
  if (router.currentRoute.name !== 'sign_in' && err.response.status === status.UNAUTHORIZED) {
    store.commit('user', null)
    router.push({ name: 'sign_in' })
  }
  return Promise.reject(err)
})

// すべてのリクエストにauthヘッダーを付与
axios.interceptors.request.use((config) => {
  const headers = store.getters['auth']
  config.headers = headers
  return config
})


// Role
import { can } from './utils/acl'
Vue.prototype.$can = (...args) => can(store.state.user, ...args)


// Filter
Vue.filter('date', (value, format = 'YYYY/MM/DD HH:mm') => {
  if (!value) return null
  return dayjs(value.toString()).format(format)
})


// Vue.prototype.$appEnv = 'development'
import DebugLog from './components/debug_log'
import ErrorMessages from './components/error_messages'
import AppSidebar from './components/sidebar'
Vue.component('debug-log', DebugLog)
Vue.component('error-messages', ErrorMessages)


Vue.use(VueRouter)
Vue.use(VueCookie)
Vue.use(Vuex)
Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  Vue.use(Toasted, {
    theme: 'toasted-primary',
    position: 'bottom-center',
    containerClass: 'is-edge',
    iconPack: 'fontawesome',
    duration: 5000
  })

  Vue.toasted.register('error',
    (message) => {
      return message
    },
    { type: 'show', icon: 'exclamation-circle' }
  )

  const el = document.getElementById('vue-app')
  if (el == null) return

  const app = new Vue({
    el: el,
    router,
    store,
    template: '<App/>',
    render: h => h(App)
  })
})

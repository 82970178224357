<template>
  <div class="message is-warning" v-if="errors">
    <div class="message-body has-text-weight-bold">
      <div class="content">
        <p class="mb-2" v-if="message">{{ message }}</p>
        <ul class="mt-0 ml-5">
          <li v-for="error in errors">{{ error }}</li>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: ['errors', 'message'],
}
</script>

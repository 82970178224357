<template>
  <div id="app">
    <router-view />
    <vue-progress-bar />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'app',
  beforeCreate() {
    // ログインユーザーの情報（ex: 氏名や権限など）を変更した際にリロードで反映されるようにする
    if (this.$store.state.user) {
      axios.get('/api/v1/users/me').then((res) => {
        // userをVuexとCookieに保存する
        const user = res.data
        this.$store.commit('user', user)

        let session = this.$cookie.get('session')
        if (session) {
          session = JSON.parse(session)
          session['user'] = user
          this.$cookie.set('session', JSON.stringify(session), { expires: '14D' })
        }
      }).catch((err) => {
        // メールアドレスを変更すると500エラーが発生する
        if (err.response.status === 500) {
          this.$toasted.global.error("エラーが発生しました<br>ログアウトして再度ログインし直してください")
        }
      })
    }
  }
}
</script>

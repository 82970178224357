import VueRouter from 'vue-router'
import VueCookie from 'vue-cookie'
import axios from 'axios'
import store from './store'
import { can } from './utils/acl'

const routes = [
  // Auth
  {
    path: '/users/sign_in',
    name: 'SignIn',
    component: () => import('./views/users/sign_in.vue'),
    meta: { isPublic: true }
  },

  // Users
  {
    path: '/',
    name: 'UsersIndex',
    component: () => import('./views/users/index.vue')
  }, {
    path: '/users/:user_id',
    name: 'UsersShow',
    component: () => import('./views/users/show.vue')
  },

  // Manage
  {
    path: '/manage/new',
    name: 'ManageNew',
    component: () => import('./views/manage/new.vue'),
    meta: { acl: ['create', 'user'] }
  }, {
    path: '/manage/import',
    name: 'ManageImport',
    component: () => import('./views/manage/import.vue'),
    meta: { acl: ['import', 'user'] }
  }, {
    path: '/manage/upload_image',
    name: 'ManageUploadImage',
    component: () => import('./views/manage/upload_image.vue'),
    meta: { acl: ['updateImage', 'user'] }
  },

  // ManageUsers
  {
    path: '/manage/users/:user_id',
    name: 'ManageUsersEdit',
    component: () => import('./views/manage/users/edit.vue')
  }, {
    path: '/manage/users/:user_id/image',
    name: 'ManageUsersImage',
    component: () => import('./views/manage/users/image.vue'),
    meta: { acl: ['updateImage', 'user'] }
  }, {
    path: '/manage/users/:user_id/password',
    name: 'ManageUsersPassword',
    component: () => import('./views/manage/users/password.vue'),
    meta: { acl: ['updatePassword', 'user'] }
  }, {
    path: '/manage/users/:user_id/roles',
    name: 'ManageUsersRoles',
    component: () => import('./views/manage/users/roles.vue'),
    meta: { acl: ['updateRole', 'user'] }
  }, {
    path: '/manage/users/:user_id/status',
    name: 'ManageUsersStatus',
    component: () => import('./views/manage/users/status.vue'),
    meta: { acl: ['updateStatus', 'user'] }
  },

  // Settings
  {
    path: '/settings',
    redirect: '/settings/fields',
  },
  // Settings/Fields
  {
    path: '/settings/fields',
    name: 'SettingsFieldsIndex',
    component: () => import('./views/settings/fields/index.vue'),
    meta: { acl: ['create', 'field'] }
  }, {
    path: '/settings/fields/new',
    name: 'SettingsFieldsNew',
    component: () => import('./views/settings/fields/new.vue'),
    meta: { acl: ['create', 'field'] }
  }, {
    path: '/settings/fields/:fieldKey/edit',
    name: 'SettingsFieldsEdit',
    component: () => import('./views/settings/fields/edit.vue'),
    meta: { acl: ['create', 'field'] }
  },
  // Settings/Permissions
  {
    path: '/settings/permissions',
    name: 'SettingsPermissionsIndex',
    component: () => import('./views/settings/permissions/index.vue'),
    meta: { acl: ['create', 'permission_group'] }
  }, {
    path: '/settings/permissions/new',
    name: 'SettingsPermissionsNew',
    component: () => import('./views/settings/permissions/new.vue'),
    meta: { acl: ['create', 'permission_group'] }
  }, {
    path: '/settings/permissions/:groupId/edit',
    name: 'SettingsPermissionsEdit',
    component: () => import('./views/settings/permissions/edit.vue'),
    meta: { acl: ['create', 'permission_group'] }
  },
  // Settings/Layout
  {
    path: '/settings/layout',
    name: 'SettingsLayoutIndex',
    component: () => import('./views/settings/layout/index.vue'),
    meta: { acl: ['create', 'layout'] }
  },

  // Admin
  {
    path: '/admin',
    redirect: '/admin/companies',
    meta: { acl: ['create', 'company'] }
  },
  // Admin/Companies
  {
    path: '/admin/companies',
    name: 'AdminCompaniesIndex',
    component: () => import('./views/admin/companies/index.vue'),
    meta: { acl: ['create', 'company'] }
  }, {
    path: '/admin/companies/new',
    name: 'AdminCompaniesNew',
    component: () => import('./views/admin/companies/new.vue'),
    meta: { acl: ['create', 'company'] }
  }, {
    path: '/admin/companies/:companyId/edit',
    name: 'AdminCompaniesEdit',
    component: () => import('./views/admin/companies/edit.vue'),
    meta: { acl: ['create', 'company'] }
  },

  // NotFound
  {
    path: '/error',
    name: 'Error',
    component: () => import('./views/error.vue'),
    meta: { isPublic: true }
  }, {
    path: '*',
    name: 'NotFound',
    component: () => import('./views/error.vue'),
    meta: { isPublic: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  routes
})

// 権限チェック
router.beforeEach((to, from, next) => {
  const requiresAuth = !!!to.matched.some(record => record.meta.isPublic)
  const acl = to.meta.acl

  // ユーザーを取得
  let user = store.state.user
  if (!user) {
    // userがVuexから取得できない場合はCookieから取得
    const existingSession = VueCookie.get('session')

    if (existingSession && existingSession.length) {
      const session = JSON.parse(existingSession)
      store.commit('user', session.user)
      store.commit('auth', session.tokens)
      user = store.state.user
    }
  }

  if (requiresAuth) {
    if (user) {
      if (acl && !can(user, acl[0], acl[1])) {
        // 権限エラー
        next({ name: 'Error', query: { code: '401' } })
      } else {
        // 権限がある or 権限不要
        next()
      }
    } else {
      // 未ログイン
      next({ name: 'SignIn' })
    }
  } else {
    // ログイン不要
    next()
  }
})


// 必ずServerのバージョン（api/versions_controller.rb）と合わせる
const CLIENT_VERSION = 6

function versionCheck() {
  axios.get('/api/version').then(res => {
    if (CLIENT_VERSION < res.data.app_version) {
      if (confirm("新しいアップデートがあります。ページを更新してください。")) location.reload(true)
    }
  })
}

router.afterEach((to, from) => {
  versionCheck()
})

// HACK: デプロイ後のchunkエラーを回避
// https://github.com/nuxt/nuxt.js/issues/3389#issuecomment-431609130
router.onError((error) => {
  const isChunkLoadFailed = /Loading(\sCSS)? chunk (\d)+ failed/.test(error.message)
  if (isChunkLoadFailed && location.hash !== '#retry') {
    location.hash = '#retry'
    location.reload(true)
  }
})

export default router

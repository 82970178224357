<template>
  <div class="sidebar">
    <!-- <div class="sidebar-header">
      <div class="is-flex is-align-items-center">
        <router-link to="/" class="logo" style="width: 100%;" content="ホーム" v-tippy="tippyConfig">
          <span style="font-size: 1.5rem;">
            <fa-icon :icon="['fas', 'address-card']" fixed-width />
          </span>
          <img class="logo-image" src="../../images/logo_inverted.png" width="70">
        </router-link>
      </div>
    </div> -->

    <div class="sidebar-body">
      <ul>
        <li content="メンバーを探す" v-tippy="tippyConfig">
          <router-link :to="{name: 'UsersIndex'}" :class="{'is-active': $route.name === 'UsersShow'}" exact>
            <b-icon icon="search" size="is-medium" />
          </router-link>
        </li>

        <li content="メンバー管理" v-tippy="tippyConfig" v-if="$can('create', 'user')">
          <router-link :to="{name: 'ManageNew'}" :class="{'is-active': $route.name.startsWith('Manage')}" exact>
            <b-icon icon="user-tie" size="is-medium" />
          </router-link>
        </li>

        <li content="システム設定" v-tippy="tippyConfig" v-if="currentUser.company_role === 'admin'">
          <router-link :to="{name: 'SettingsFieldsIndex'}" :class="{'is-active': $route.name.startsWith('Settings')}" exact>
            <b-icon icon="cog" size="is-medium" />
          </router-link>
        </li>
      </ul>
    </div>

    <div class="sidebar-footer">
      <ul>
        <li>
          <b-dropdown position="is-top-right" :append-to-body="true" class="profile-dropdown">
            <template #trigger>
              <img :src="currentUser.image_medium_url" width="40" height="40" class="is-clickable">
            </template>

            <b-dropdown-item :custom="true">
              <p><b>{{ currentUser.full_name }}</b></p>
              <p><small class="has-text-grey">{{ currentUser.email }}</small></p>
            </b-dropdown-item>

            <b-dropdown-item has-link>
              <router-link :to="{name: 'UsersShow', params: {user_id: currentUser.id}}" active-class="">プロフィール</router-link>
            </b-dropdown-item>
            
            <hr class="dropdown-divider">
            <b-dropdown-item has-link>
              <a @click="signOut">
                <fa-icon :icon="['fas', 'sign-out-alt']" />
                ログアウト
              </a>
            </b-dropdown-item>
          </b-dropdown>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
export default {
  data() {
    return {
      currentUser: this.$store.state.user,
      composing: false, // IME入力時にtrueになる
      reduce: false,
    }
  },
  methods: {
    signOut () {
      this.$cookie.delete('session')
      this.$router.push({ name: 'SignIn' })
    }
  },
  computed: {
    expandUserManage () {
      return ['UsersNew', 'UsersImport'].includes(this.$route.name)
    },
    expandSettings () {
      return this.$route.name.startsWith('Settings')
    },
    tippyConfig() {
      return { placement: 'right', distance: 30, arrow: true, boundary: 'viewport', animation: 'fade', duration: [0, 0] }
    }
  }
}
</script>


<style lang="scss" scoped>
.sidebar {
  background-color: #1b55a7;
  color: #fff;
  text-align: center;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  
  .sidebar-header {
    margin-bottom: 1.5rem;

    a {
      color: #fff;

      svg {
        margin-bottom: 0.1rem;
        margin-right: 0.5rem;
      }
    }
  }

  .sidebar-body {
    flex: 1;
  }

  .sidebar-footer {
    margin-top: 2rem;

    img {
      background-color: #fff;
      border-radius: 100%;
    }
  }

  ul {
    a {
      color: rgba(255, 255, 255, 0.5);
      display: inline-block;
      padding: 0.5rem 0;

      &.is-active, &:hover {
        color: #fff;
      }
    }
  }
}
</style>


<style lang="scss">
.profile-dropdown .dropdown-menu {
  transform: translate(48px, 28px);

  .dropdown-content {
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.2em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  }
}
</style>

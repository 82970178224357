<template>
  <div class="debug" v-if="isShow">
    <div class="pre" v-if="type == 'textarea'"><slot /></div>
    <div v-else><slot /></div>
  </div>
</template>


<script>
export default {
  props: ['type'],
  data() {
    return {
      isShow: false
    }
  },
}
</script>


<style lang="scss" scoped>
.debug {
  background-color: whitesmoke;
  border-radius: 3px;
  padding: 1.5rem;
  position: relative;
  min-height: 2rem;
  max-height: 10rem;
  overflow-y: scroll;

  textarea {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
  }
}

.debug::before {
  background-color: #dbdbdb;
  content: "DEBUG";
  color: #888;
  font-size: 0.75em;
  padding: 0.25em 0.6em;
  position: absolute;
  top: 0;
  right: 0;
}

.pre {
  white-space: pre;
}
</style>
